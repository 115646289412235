import { DataAccess } from "../../dataaccess/data.access";

export class PushNotifyService {
    public static notify(title: string, body: string, notifyIds: number[]): void {
        const payload = {
            forceView: "tasks",
            notification_foreground: "true",
        };
        DataAccess.post(
            `/device/task/notification.json?title=${encodeURIComponent(title)}&body=${encodeURIComponent(body)}&notifyIds=${notifyIds.join("+")}`,
            payload,
        );
    }
}
