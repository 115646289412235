import { AddressI } from "../../pojo/AddressI";
import { Task } from "../../pojo/Task";
import { Proto } from "../../pojo/Proto";
import CharBoolean = Proto.CharBoolean;
import NumericalString = Proto.NumericalString;
import StateName = Task.StateName;
import { S25ItemI } from "../../pojo/S25ItemI";

export namespace Bpe {
    export type BpeScenarioTypes = "event-form" | "express" | "event-state-change" | "task" | "cancelTodos";

    export type BpeVarsCombined = {
        taskActions: Tasks.ActionsMap;
    } & BpeVars &
        Partial<BpePreVars>;

    export type BpeVars = {
        actionType: "create" | "edit";
        timeFormat: string;
        dateFormat: string;
        dateTimeFormat: string;
        source: any; //source,
        eventId: number;
        eventUrl: string;
        eventLink: string; //htmlString
        homeUrl: string; //url
        eventLocator: string;
        eventName: string;
        eventTitle: string;
        versionNumber: number;
        eventTypeId: number;
        eventTypeName: string;
        description: string; //this is rich text
        internalNote: string;
        confirmationNote: string;
        comment: string;
        customAttributes: any;
        customAttributesList: any[];
        categories: {
            id: number;
            isActive: "Yes" | "No";
            name: string;
        }[];
        billItems: any; //billItemList,  relationships,  tasks,  contacts,  segments,  organizationsList,
        roleContactId: number;
        roleContactName: string;
        roleContactEmail: string;
        roleContactAddr: AddressI;
        roleContactFirst: string;
        roleContactLast: string;
        roleContactMiddle: string;
        roleContactPhone: string;
        roleContactFax: string;
        expectedCount: NumericalString;
        registeredCount: NumericalString;
        state: number;
        stateName: string;
        requesterEmail: string;
        requestorEmail: string; //alternate spelling
        schedulerEmail: string;
        primaryOrganization: any; //primaryOrganization,
        requirements: any; //requirements,
        organizations: any; //orgVars,
        dates: {
            lastModDate: Date;
            creationDate: Date;
            currentLocalDate: Date;
            //reservation start and end dates from earliest/ latest reservations
            startDate: Date;
            endDate: Date;
            eventStartDate: Date;
            eventEndDate: Date;
            eventSetupDate: Date;
            eventTakedownDate: Date;
            preEventDate: Date;
            postEventDate: Date;
        };
        pricing: {
            grandTotal: string;
        };
        durations: {
            setup: string; //duration
            takeDown: string; //duration,
            preEvent: string; //duration,
            postEvent: string; //duration,
            event: string; //duration,
            preToPost: string; //duration,
            setupToTakedown: string; //duration,
        };

        //occurrence info:
        occurrences: any[]; //globalOccurrences,
        occurrencesResources: any[];
        occurrencesLocations: any[];
        occurrencesLocationsResources: any[]; //occurrencesLocations.concat(occurrencesResources).sort((a, b) => a.dates.rsrvStartDate - b.dates.rsrvStartDate),
        locations: any; //globalLocations,
        resources: any; //globalResources,

        //pref occurrence info:
        prefOccurrences: any; //globalPrefOccurrences,
        prefOccurrencesResources: any;
        prefOccurrencesLocations: any;
        prefOccurrencesLocationsResources: any; // prefOccurrencesLocations.concat(prefOccurrencesResources.sort((a, b) => a.dates.rsrvStartDate - b.dates.rsrvStartDate),
        prefLocations: any; // globalPrefLocations,
        prefResources: any; // globalPrefResources,
        tasks: Task[];

        //todo info:
        todo: any[];

        //currentUser
        currentGroupId: number;
        currentContactName: string;

        scenario: any; // is this needed??
        template: any;

        //functions
        testTaskTriggers: Function;
        testAttributes: Function;
        testHeadcount: Function;
    };

    export type BpePreVars = {
        preTimeFormat: string;
        preDateFormat: string;
        preDateTimeFormat: string;
        preSource: any; //source,
        preEventId: number;
        preEventUrl: string;
        preEventLink: string; //htmlString
        preHomeUrl: string; //url
        preEventLocator: string;
        preEventName: string;
        preEventTitle: string;
        preVersionNumber: number;
        preEventTypeId: number;
        preEventTypeName: string;
        preDescription: string; //this is rich text
        preInternalNote: string;
        preConfirmationNote: string;
        preComment: string;
        preCustomAttributes: any;
        preCustomAttributesList: any[];
        preCategories: {
            id: number;
            isActive: "Yes" | "No";
            name: string;
        }[];
        preBillItems: any; //billItemList,  relationships,  tasks,  contacts,  segments,  organizationsList,
        preRoleContactId: number;
        preRoleContactName: string;
        preRoleContactEmail: string;
        preRoleContactAddr: AddressI;
        preRoleContactFirst: string;
        preRoleContactLast: string;
        preRoleContactMiddle: string;
        preRoleContactPhone: string;
        preRoleContactFax: string;
        preExpectedCount: number;
        preRegisteredCount: number;
        preState: number;
        preStateName: string;
        preRequesterEmail: string;
        preRequestorEmail: string; //alternate spelling
        preSchedulerEmail: string;
        prePrimaryOrganization: any; //primaryOrganization,
        preRequirements: any; //requirements,
        preOrganizations: any; //orgVars,
        preDates: {
            lastModDate: Date;
            creationDate: Date;
            currentLocalDate: Date;
            //reservation start and end dates from earliest/ latest reservations
            startDate: Date;
            endDate: Date;
            eventStartDate: Date;
            eventEndDate: Date;
            eventSetupDate: Date;
            eventTakedownDate: Date;
            preEventDate: Date;
            postEventDate: Date;
        };
        prePricing: {
            grandTotal: number;
        };
        preDurations: {
            setup: string; //duration
            takeDown: string; //duration,
            preEvent: string; //duration,
            postEvent: string; //duration,
            event: string; //duration,
            preToPost: string; //duration,
            setupToTakedown: string; //duration,
        };

        //occurrence info:
        preOccurrences: any[]; //globalOccurrences,
        preOccurrencesResources: any[];
        preOccurrencesLocations: any[];
        preOccurrencesLocationsResources: any[]; //occurrencesLocations.concat(occurrencesResources).sort((a, b) => a.dates.rsrvStartDate - b.dates.rsrvStartDate),
        preLocations: any; //globalLocations,
        preResources: any; //globalResources,

        //pref occurrence info:
        prePrefOccurrences: any; //globalPrefOccurrences,
        prePrefOccurrencesResources: any;
        prePrefOccurrencesLocations: any;
        prePrefOccurrencesLocationsResources: any; // prefOccurrencesLocations.concat(prefOccurrencesResources.sort((a, b) => a.dates.rsrvStartDate - b.dates.rsrvStartDate),
        prePrefLocations: any; // globalPrefLocations,
        prePrefResources: any; // globalPrefResources,
        preTasks: Task[];

        //todo info:
        preTodo: boolean;
    };

    export type Task = {
        comment: string;
        id: number; // task_id
        name: string; //Object name,
        state: StateName;
        stateId: Task.State;
        type: Task.IdName;
        flagged: CharBoolean;
        assignedTo: string; //username
        assignedToIds: number[];
        object: "event type" | "space" | "resource" | "organization" | "requirement";
        respondByDate: Date;
        firstDate: Date;
        item: S25ItemI;
    };

    export namespace Tasks {
        export type Action = Task.Actions | Actions;
        export enum Actions {
            create = "create",
            delete = "delete",
            none = "none",
        }

        export type ActionsMap = {
            [key in Action]: Bpe.Task[];
        };
    }
}
